<template>
  <div class="fc fg-1 h-100vh v-100vw ai-c jc-c">
    <Load :action="ACTIONS.LOGIN" title="Signing in">
      <div class="fc fg-1">
        <p>
          <span style="width: 90px; display: inline-block">Tài khoản:</span>
          <input :value="username" @input="updateUsername"/>
        </p>
        <p>
          <span style="width: 90px; display: inline-block">Mật khẩu:</span>
          <input :value="password" @input="updatePassword"/>
        </p>
        <button @click="() => login(username, password)">Đăng nhập</button>
      </div>
    </Load>
  </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';
import notification from '@/components/UiLib/Api/notification';
import UserApi from '@/api/user';
import loading from '@/components/UiLib/Api/loading';
import Load from '@/components/UiLib/Load';
import axios from 'axios';

const ACTIONS = {
  LOGIN: 'login'
}

const username = ref('')
const password = ref('')
const updateUsername = (e) => username.value = e.target.value
const updatePassword = e => password.value = e.target.value

const login = (u, p) => {
  u = _.trim(u)
  p = _.trim(p)
  if (!u || !p) {
    return notification.err('Thiếu thông tin đăng nhập')
  }
  loading.begin(ACTIONS.LOGIN)
  UserApi.login(u, p).then(({user}) => {
    if (user) {
      window.user = user
      window.$router.push({ path: '/' })
    } else {
      notification.err('Đăng nhập thất bại')
    }
  }).finally(() => loading.end(ACTIONS.LOGIN))
}
</script>
