<template>
  <div class="px-3 py-3 fc fg-1 w-100 h-100" style="background: #fff">
    <p style="font-size: 20px; font-weight: 600">Worker {{item._id}} ({{item.ip}}) <Load :action="ACTIONS.LOAD_TABS"/></p>
    <div class="ovf-y-s hide-scroll-bar">
      <collapsable-section v-for="tab in Object.keys(tabEvents)" :key="tab" :title="getTitle(tab)" style="border-bottom: 1px solid #eee">
        <template #more>
          <button @click="() => release(tab)">Release</button>
        </template>
        <p v-for="ev in tabEvents[tab]" :key="ev.t">
          {{dayjs(ev.t).format('DD/MM HH:mm:ss')}}: {{ev.e}}
        </p>
      </collapsable-section>
    </div>
    <spacer/>
    <div class="fr ai-c jc-fe">
      <button @click="close">Đóng</button>
    </div>
  </div>
</template>

<script setup>
import CollapsableSection from '@/components/UiLib/CollapsableSection';
import Spacer from '@/components/UiLib/Spacer';
import {onBeforeUnmount, onMounted, ref} from 'vue';
import api from '@/api';
import notification from '@/components/UiLib/Api/notification';
import dayjs from 'dayjs';
import loading from '@/components/UiLib/Api/loading';
import Load from '@/components/UiLib/Load';

const props = defineProps({ item: Object })
const emit = defineEmits((['close']))

const ACTIONS = {
  LOAD_TABS: 'dcvps:loadTabs'
}

let loadTabInterval;
const tabs = ref([])
const tabEvents = ref({})
const loadingEvents = ref({})
const loadTabs = async () => {
  loading.begin(ACTIONS.LOAD_TABS)
  try {
    const vps = await api.vps.read(props.item._id)
    tabs.value = vps.tabs
    for (const tab of tabs.value) {
      loadingEvents.value[tab] = true;
      if (!tabEvents.value[tab]) {
        api.tabEvent.read(tab, props.item._id).then(events => {
          setTimeout(() => loadingEvents.value[tab] = false, 100)
          tabEvents.value[tab] = events
        })
      }
    }
  } catch (e) {
    notification.err(e)
  }
  setTimeout(() => loading.end(ACTIONS.LOAD_TABS), 100)
}
onMounted(() => {
  loadTabs()
  loadTabInterval = setInterval(loadTabs, 10000)
  window.socket.emit('watch', props.item._id, 'tabEvent')
  window.socket.on('tabEvent:create', payload => {
    if (payload.w === props.item._id) {
      console.log('tabEvent:create', payload)
      if (tabEvents.value[payload.a]) {
        tabEvents.value[payload.a].push(payload)
      } else {
        tabEvents.value[payload.a] = [payload]
      }
    }
  })
})

onBeforeUnmount(() => {
  clearInterval(loadTabInterval)
  window.socket.emit('un-watch', props.item._id, 'tabEvent')
  window.socket.off('tabEvent:create')
})

const getTitle = tab =>  `${tab.split(':')[0]} ${loadingEvents.value[tab] ? '...' : ''}`
const release = tab => window.rabbitmq.toWorker('tab:release', {workerId: props.item._id, taskId: tab})
const close = () => emit('close')
</script>
