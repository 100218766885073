<template>
  <div class="w-100 h-100vh">
    <div class="fr ai-c fg-1 px-2 py-2" style="height: 50px">
      <input :value="newErrorFilter" @input="updateNewErrorFilter"/>
      <button @click="addErrorFilter">Add new</button>
      <Spacer/>
      <button @click="apply">Apply</button>
    </div>
    <div class="table w-100 ovf-y-s hide-scroll-bar">
      <table class="w-100">
        <thead>
        <th>Error</th>
        <th>Enabled</th>
        <th>Action</th>
        </thead>
        <tbody>
        <tr v-for="errorFilter of errorFilters" :key="errorFilter._id">
          <td><span>{{errorFilter.error}}</span></td>
          <td><span>{{errorFilter.enabled}}</span></td>
          <td><button @click="toggleEnabled(errorFilter)">{{ errorFilter.enabled ? 'Turn Off' : 'Turn On' }}</button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import {ref, onMounted, onBeforeUnmount} from 'vue'
import api from '@/api';
import loading from '@/components/UiLib/Api/loading';
import notification from '@/components/UiLib/Api/notification';
import Spacer from '@/components/UiLib/Spacer';

const ACTIONS = {
  GET_ERROR_FILTERS: 'error-filter:gets',
}
const errorFilters = ref([])
const getErrorFilters = () => {
  loading.begin(ACTIONS.GET_ERROR_FILTERS)
  api.script.getErrorFilters().then(rs => {
    console.log('getErrorFilters', rs)
    errorFilters.value = rs
  })
  .catch(e => notification.err(e))
  .finally(() => loading.end(ACTIONS.GET_ERROR_FILTERS))
}
const updateErrorFilter = (_id, change) => api.script.updateErrorFilter(_id, change).catch(e => notification.err(e))
const toggleEnabled = (item) => updateErrorFilter(item._id, { enabled: !item.enabled })

const newErrorFilter = ref('')
const updateNewErrorFilter = e => newErrorFilter.value = e.target.value
const addErrorFilter = () => {
  const error = _.trim(newErrorFilter.value)
  if (_.isEmpty(error)) {
    notification.err('Empty value is not allowed')
    return
  }
  api.script.createErrorFilter(newErrorFilter.value).then(() => {
    notification.success('Created')
    newErrorFilter.value = ''
  })
}

const apply = () => api.script.applyErrorFilters()

onMounted(() => {
  getErrorFilters()
  window.socket.emit('watch', 'script-error-filter')
  window.socket.on(`script-error-filter:create`, change => {
    console.log(`script-error-filter:create`, change)
    errorFilters.value.push(change)
  })
  window.socket.on(`script-error-filter:update`, (_id, change) => {
    console.log('script-error-filter:update', _id, change)
    errorFilters.value.forEach(ef => {
      if (ef._id === change._id)
        ef.enabled = change.enabled
    })
  })
})
onBeforeUnmount(() => {
  window.socket.emit('un-watch', 'script-error-filter')
  window.socket.off(`script-error-filter:create`)
  window.socket.off(`script-error-filter:update`)
})
</script>
<style scoped lang="scss">
.table {
  height: calc(100% - 50px);
  font-size: 12px;
  color: #fff;

  table {
    border-collapse: collapse;
    th, td {
      height: 30px;
      padding: 5px 16px;
      background-color: #1e1e1e;
      color: #ddd;
      border-bottom: thin solid hsla(0, 0%, 100%, .12);
    }

    th {
      text-align: left;
      position: sticky;
      top: 0;
      color: #aaa;
      height: 48px;
    }
  }
}
</style>
