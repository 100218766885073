import Socket from 'socket.io-client';
import {BE_URL} from '@/constants';

const socket = Socket(`${BE_URL}?role=controller`)
// TODO: CORS -> failed to connect socket server
socket.on('connect', (args) => {
  console.log('connected to master. fetching scripts...', args)
})

export default socket
