<template>
  <router-view #default="{ Component }">
    <component :is="Component"/>
  </router-view>
  <api/>
</template>

<script>
import Api from '@/components/UiLib/Api/Api';

export default {
  name: 'App',
  components: {Api}
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Sans-Serif;
}

.fn-btn {
  width: 20px; height: 20px; padding: 0;
}
</style>
