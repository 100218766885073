import { createApp } from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import App from './App.vue';
import socket from './socket/socket'
import Index from './pages/index';
import Analytics from '@/pages/analytics';
import SignIn from '@/pages/signIn';
import UserApi from '@/api/user';

async function initApp() {
  const access_token = localStorage.getItem('access_token')
  if (access_token) {
    console.log('has access token, try to auth')
    try {
      const {user} =  await UserApi.auth(access_token);
      if (user) {
        console.log('Auth succeeded')
        window.user = user
      } else {
        console.error('Failed to auth')
      }
    } catch (e) {
      console.error('Failed to auth', e)
    }
  }

  const app = createApp(App);

  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {path: '/', component: Index},
      {path: '/sign-in', component: SignIn},
      {path: '/analytics/:id', name: 'analytics',  component: Analytics }
    ]
  }, {default: '/'})


  router.beforeEach((to, from, next) => {
    if (to.path === '/sign-in' || window.user) {
      next()
    } else {
      next('/sign-in')
    }
  })
  app.use(router);

  window.$router = router;
  window.socket = socket;
  window.rabbitmq = {
    toWorker: (event, data) => window.socket.emit('rabbitmq', `worker:message:${window.user._id}`, event, data),
    toWatcher: (event, data) => window.socket.emit('rabbitmq', `watcher:message:${window.user._id}`, event, data)
  }

  router.isReady().then(() => app.mount('#app'));
}

initApp();
