<template>
  <div class="c-gre-0 w-100 h-100vh">
    <div class="fr ai-c fg-1 px-2 py-2" style="height: 50px">
      <span>Script Errors</span>
      <Spacer/>
      <button @click="aggregateError">Refresh</button>
    </div>
    <Load :action="ACTIONS.AGGREGATE_ERROR">
      <div class="table w-100 ovf-y-s hide-scroll-bar">
        <table class="w-100">
          <thead>
          <tr>
            <th>Qty</th>
            <th>Error</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="error in errorAggregate" :key="error._id">
            <td>{{error.total}}</td>
            <td>{{error._id}}</td>
            <td>
              <div class="fr ai-c fg-1">
                <button @click="inspect(error._id)">
                  <icon>fas fa-search@16</icon>
                </button>
                <button @click="deleteError(error._id)">
                  <icon>fas fa-times@16:#d50404</icon>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </Load>
  </div>
</template>
<script setup>
import {ref, onMounted} from 'vue';
import hmm from '@/api/hmm';
import notification from '@/components/UiLib/Api/notification';
import loading from '@/components/UiLib/Api/loading';
import Load from '@/components/UiLib/Load';
import Spacer from '@/components/UiLib/Spacer';
import Icon from '@/components/UiLib/Icon';
const ACTIONS = {
  AGGREGATE_ERROR: 'se:aggr-err',
  READ_10_ERRORS: 'se:read-10-err',
  DELETE_ERR: 'se:delete-err'
}
const errorAggregate = ref({})
const aggregateError = () => {
  loading.begin(ACTIONS.AGGREGATE_ERROR)
  const qry = [
    {$group: { _id: "$error", total: { $sum: 1 } } },
    {$sort: { total: -1 }},
    {$limit: 50}
  ]
  hmm.scriptError.aggregate(qry).$
  .then(rs =>  errorAggregate.value = rs)
  .catch(notification.err)
  .finally(() => loading.end(ACTIONS.AGGREGATE_ERROR))
}
const inspect = error => {
  loading.begin(ACTIONS.READ_10_ERRORS)
  hmm.scriptError.find({ error }).sort({createdDate: -1}).limit(10).$
  .then(console.log)
  .catch(notification.err)
  .finally(() => loading.end(ACTIONS.READ_10_ERRORS))
}
const deleteError = error => {
  loading.begin(ACTIONS.DELETE_ERR)
  hmm.scriptError.deleteMany({error}).$
  .then(() => notification.success('Errors deleted'))
  .catch(notification.err)
  .finally(() => loading.end(ACTIONS.DELETE_ERR))
}

onMounted(() => aggregateError())
</script>
<style scoped lang="scss">
.table {
  height: calc(100% - 50px);
  font-size: 12px;
  color: #fff;

  table {
    border-collapse: collapse;
    th, td {
      height: 30px;
      padding: 5px 16px;
      background-color: #1e1e1e;
      color: #ddd;
      border-bottom: thin solid hsla(0, 0%, 100%, .12);
    }

    th {
      text-align: left;
      position: sticky;
      top: 0;
      color: #aaa;
      height: 48px;
    }
  }
}
</style>
