<template>
  <div class="h-100 w-100">
    <page-header>
      <span class="mr-2">{{ vpsMetricStr }}</span>
    </page-header>
    <div class="fr" style="height: calc(100% - 50px)">
      <tableu :api="api.vps" :parse-item="parseItem" :items-loaded="onItemsLoaded" edge="vps">
        <template #thead>
          <tr>
            <th>Mã VPS</th>
            <th>Địa chỉ IP</th>
            <th>Kịch bản</th>
            <th>Trạng thái</th>
            <th>Tab R/A/C ({{ totalRunningTabs }}/{{ totalAllocTabs }}/{{ totalConfigTabs }})</th>
            <th>Thông tin</th>
            <th>Ngày tạo</th>
            <th>Full Disk?</th>
            <th>Hành động</th>
          </tr>
        </template>
        <template #body="{ items, updateItem }">
          <VPSItem v-for="item of items" :item="item" :key="item._id" />
        </template>
        <template #footer>
          <tooltip content="Cập nhật phần mềm">
            <button @click="updateWorkers">U</button>
          </tooltip>
          <tooltip content="Thu hồi tài nguyên">
            <button @click="releaseResource">R</button>
          </tooltip>
          <tooltip content="Chạy toàn bộ">
            <button @click="startWorkers">
              <icon>fas fa-play@16:#43a047</icon>
            </button>
          </tooltip>
          <tooltip content="Dừng toàn bộ">
            <button @click="stopWorkers">
              <icon>fas fa-pause@16:#d50404</icon>
            </button>
          </tooltip>
          <tooltip content="Tìm kiếm">
            <button @click="find">
              <icon>fas fa-search@16</icon>
            </button>
          </tooltip>
        </template>
      </tableu>
    </div>
  </div>
</template>
<script setup>
import PageHeader from '@/components/PageHeader';
import api from '@/api'
import Tableu from '@/components/UiLib/Tableu';
import { metric2Str } from '@/utils';
import msgBox from '@/components/UiLib/Api/msg-box';
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import dialog from '@/components/UiLib/Api/dialog';
import VPSItem from '@/components/VPSItem';
import Icon from '@/components/UiLib/Icon';
import Tooltip from '@/components/UiLib/Tooltip';
import DCFindVps from '@/components/DCFindVps';

function parseItem(line) {
  return {
    ip: line,
    connectStatus: 'OFFLINE', // see controler-be/src/constants.ts:VPSconnectStatus
    runningTabs: 0,
    createdDate: new Date()
  }
}
const onItemsLoaded = items => _.sortBy(items, ['connectStatus', 'fullDisk'])
function releaseResource() {
  msgBox.show('Xác nhận', 'Thu hồi tài nguyên?').then(rs => {
    if (rs === msgBox.Results.yes)
      window.rabbitmq.toWorker('releaseAll')
  })
}
function updateWorkers() {
  msgBox.show('Xác nhận', 'Cập nhật phần mềm cho toàn bộ worker. Lưu ý, sử dụng khi thực sự chắc chắn?').then(rs => {
    if (rs === msgBox.Results.yes)
      api.systemConfig.getIndexWorkerFilePath().then(url => window.rabbitmq.toWatcher('updateAll', { url }))
  })
}
function startWorkers() {
  msgBox.show('Xác nhận', 'Chạy worker?').then(rs => {
    if (rs === msgBox.Results.yes)
      window.rabbitmq.toWatcher('startAll')
  })
}
function stopWorkers() {
  msgBox.show('Xác nhận', `Dừng worker?`).then(rs => {
    if (rs === msgBox.Results.yes)
      window.rabbitmq.toWatcher('stopAll')
  })
}
async function find() {
  await dialog.show({ component: DCFindVps })
}

let interval
const vpsMetric = ref({})
const vpsMetricStr = computed(() => metric2Str(vpsMetric.value))
const getVPSMetric = () => api.vps.metric().then(data => vpsMetric.value = data)
const totalRunningTabs = ref(0)
const getTotalRunningTabs = () => api.vps.totalRunningTabs().then(data => totalRunningTabs.value = (data.length == 0 ? 0 : data[0].sum))
const totalAllocTabs = ref(0)
const getTotalAllocTabs = () => api.vps.totalAllocTabs().then(data => totalAllocTabs.value = (data.length == 0 ? 0 : data[0].sum))
const totalConfigTabs = ref(0)
const getTotalConfigTabs = () => api.vps.totalConfigTabs().then(data => totalConfigTabs.value = (data.length == 0 ? 0 : data[0].sum))

const loadData = () => {
  getVPSMetric()
  getTotalRunningTabs()
  getTotalAllocTabs()
  getTotalConfigTabs()
}

onMounted(() => {
  loadData()
  interval = setInterval(loadData, 10000)
})
onBeforeUnmount(() => clearInterval(interval))
</script>
