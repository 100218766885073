<template>
  <div class="fc fg-2 px-3 py-3 w-100vw h-100vh" style="background: #1e1e1e">
    <h3 class="c-gre-0">Analytics</h3>
    <div v-if="video" class="fc fg-1 bc-gre-0 px-2 py-2" style="border-radius: 6px">
      <p><span style="display: inline-block; width: 110px; font-weight: 600">Url:</span> <a :href="video.url">{{ video.url }}</a></p>
      <p><span style="display: inline-block; width: 110px; font-weight: 600">Title:</span> {{ video.title }}</p>
      <p><span style="display: inline-block; width: 110px; font-weight: 600">Duration:</span> {{ video.duration }}</p>
      <p><span style="display: inline-block; width: 110px; font-weight: 600">Views:</span> {{ video.views }}</p>
      <p><span style="display: inline-block; width: 110px; font-weight: 600">Watch hours:</span> {{ readableMs(video.watchHours) }}</p>
    </div>
    <line-chart class="bc-gre-0 px-2 py-2" style="border-radius: 6px" :chart-data="chartData" :chart-options="chartOptions"/>
  </div>
</template>
<script setup>
import {useRoute} from 'vue-router'
import {computed, onMounted, ref} from 'vue';
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js'
import {Line as LineChart} from 'vue-chartjs';
import api from '@/api';
import dayjs from 'dayjs';
import {ms2H, readableMs} from '../utils';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale)
const route = useRoute()
const video = ref()
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false
}
const chartData = computed(() => {
  if (!video.value)
    return {
      labels: [],
      datasets: []
    }

  return {
    labels: video.value.analytics.map(item => dayjs(item.at).format('YYYY-MM-DD')),
    datasets: [
      {
        label: 'Views',
        backgroundColor: '#f44336',
        data: video.value.analytics.map(item => item.views),
      },
      {
        label: 'Watch hours',
        backgroundColor: '#673ab7',
        data: video.value.analytics.map(item => ms2H(item.watchHours)),
      }
    ]
  }
})
onMounted(() => api.video.read(route.params.id).then(data => video.value = data))
</script>
<style scoped>
</style>
