<template>
  <div class="h-100 w-100">
    <page-header>
      <Load :action="ACTIONS.GET_ACCOUNT_METRIC">
        <span class="mr-2" style="font-size: 13px;">
          {{accMetricStr}}
        </span>
      </Load>
    </page-header>
    <div class="fr" style="height: calc(100% - 50px)">
      <tableu :api="api.account" :parse-item="parseItem" :enrich-item="enrichItem" edge="account">
        <template #thead>
          <tr>
            <th>Tài khoản</th>
            <th>Dùng bởi</th>
            <th>Trạng thái</th>
            <th>Lỗi</th>
            <th>Premium</th>
            <th>Hết hạn</th>
            <th>Sửa lần cuối</th>
            <th>Nghỉ đến</th>
            <th>Ngày tạo</th>
            <th>Hành động</th>
          </tr>
        </template>
        <template #body="{items, deleteItem}">
          <tr v-for="item of items" :key="item._id">
            <td><span class="clickable" @click="copyToClipboard(item.account)">{{getUsername(item.account)}}</span></td>
            <td>{{item.usedByStr || item.usedBy}}</td>
            <td><div class="fr ai-c fg-1" style="text-transform: lowercase">{{item.status}}</div></td>
            <td>
              {{item.loginFailedReason}}
            </td>
            <td style="text-transform: lowercase">{{item.premium}}</td>
            <td>{{item.premiumEndDate && dayjs(item.premiumEndDate).format('DD/MM/YY')}}</td>
            <td>{{dayjs(item.lastModified).format('HH:mm DD/MM')}}</td>
            <td>{{item.lockUntil && dayjs(item.lockUntil).format('HH:mm DD/MM')}}</td>
            <td>{{dayjs(item.createdDate).format('DD/MM/YY')}}</td>
            <td>
              <div class="fr ai-c fg-1">
                <tooltip content="Làm mới account">
                  <button class="fn-btn" @click="api.account.release(item._id)">
                    <i class="material-icons" style="font-size: 16px">refresh</i>
                  </button>
                </tooltip>
                <tooltip content="Xóa">
                  <button class="fn-btn" @click="deleteItem([item._id])">
                    <icon>fas fa-times@16:#d50404</icon>
                  </button>
                </tooltip>
              </div>
            </td>
          </tr>
        </template>
        <template #footer>
          <button @click="removeByFile">Xoá theo file <Load :action="ACTIONS.REMOVE_BY_FILE"/></button>
          <button @click="removeBrokenAccount">Xoá acc lỗi <Load :action="ACTIONS.REMOVE_BROKEN"/></button>
          <button @click="removeNonePreAccount">Xoá acc thường & hết hạn <Load :action="ACTIONS.REMOVE_NORMAL_OR_EXPIRED"/></button>
        </template>
      </tableu>
    </div>
  </div>
</template>
<script setup>
import PageHeader from '@/components/PageHeader';
import api from '@/api'
import Tableu from '@/components/UiLib/Tableu';
import dayjs from 'dayjs'
import Icon from '@/components/UiLib/Icon';
import notification from '@/components/UiLib/Api/notification';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import msgBox from '@/components/UiLib/Api/msg-box';
import {onMounted, ref, computed, onBeforeUnmount} from 'vue'
import {
  getWorkerIp,
  metric2Str,
  notEmpty,
  openFileDialog,
  readFile,
  removeCarry,
  copyToClipboard,
  randomPick
} from '@/utils';
import Tooltip from '@/components/UiLib/Tooltip';
import loading from '@/components/UiLib/Api/loading';
import Load from '@/components/UiLib/Load';

const ACTIONS = {
  REMOVE_BY_FILE: 'account:remove-by-file',
  REMOVE_BROKEN: 'account:remove-broken',
  REMOVE_NORMAL_OR_EXPIRED: 'account:remove-normal-or-expired',
  GET_ACCOUNT_METRIC: 'account:get-metric'
}

let uas = []
async function loadUserAgents() {
  uas = (await api.userAgent.readAll(0, true)).map(ua => ua.userAgent)
}

function parseItem(txtLine) {
  return {
    account: txtLine,
    status: 'NEW',
    premium: 'UNDETERMINED',
    premiumEndDate: null,
    ua: randomPick(uas),
    createdDate: new Date()
  }
}

function enrichItem(item) {
  if (item.usedBy)
    getWorkerIp(item.usedBy).then(rs => item.usedByStr = rs)
}

function getUsername(account) {
  const parts = account.split(':')
  if (parts.length)
    return account.split(':')[0]
  return account
}

async function removeByFile() {
  const files = await openFileDialog({multiple: false})
  for (const file of files) {
    try {
      const content = await readFile(file)
      const accounts = content.split('\n').filter(notEmpty).map(removeCarry)
      loading.begin(ACTIONS.REMOVE_BY_FILE)
      await api.account.deleteByAccounts(accounts)
      notification.success('Đã xoá video')
      location.reload()
    } catch (e) {
      notification.err(`Lỗi: ${e.message}`)
    } finally {
      loading.end(ACTIONS.REMOVE_BY_FILE)
    }
  }
}

async function removeBrokenAccount() {
  try {
    const rs = await msgBox.show('Xác nhận', 'Xoá account lỗi?', msgBox.Buttons.YesNo, msgBox.Icons.Question)
    if (rs === msgBox.Results.no) return
    loading.begin(ACTIONS.REMOVE_BROKEN)
    const data = await api.account.deleteBroken()
    await exportAccount(data)
    location.reload()
  } catch (e) {
    notification.err(e.message)
  } finally {
    loading.end(ACTIONS.REMOVE_BROKEN)
  }
}

async function removeNonePreAccount() {
  try {
    const rs = await msgBox.show('Xác nhận', 'Xoá account thường hoặc hết hạn pre?', msgBox.Buttons.YesNo, msgBox.Icons.Question)
    if (rs === msgBox.Results.no) return
    loading.begin(ACTIONS.REMOVE_NORMAL_OR_EXPIRED)
    const data = await api.account.deleteNonePreAccounts()
    await exportAccount(data)
    location.reload()
  } catch (e) {
    notification.err(e.message)
  } finally {
    loading.end(ACTIONS.REMOVE_NORMAL_OR_EXPIRED)
  }
}

async function exportAccount(accounts) {
  const wb = XLSX.utils.book_new()
  const data = [
    [`Tong:${accounts.length}`],
    [''],
    ['Tai khoan', 'Loi', 'Premium', 'Ngay het han', 'Ngay them vao'],
    ..._.map(accounts, acc => [acc.account, acc.loginFailedReason, acc.premium, dayjs(acc.premiumEndDate).format('YYYY-MM-DD'), dayjs(acc.createdDate).format('YYYY-MM-DD')])
  ]
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data), dayjs().format('YYYY-MM-DD'));
  XLSX.writeFile(wb, 'broken_accounts.xlsx')
}

let loadAccMetricInterval
const accMetric = ref({})
const accMetricStr = computed(() => metric2Str(accMetric.value))
const getAccMetric = () => {
  loading.begin(ACTIONS.GET_ACCOUNT_METRIC)
  api.account.metric()
    .then(data => accMetric.value = data)
    .finally(() => loading.end(ACTIONS.GET_ACCOUNT_METRIC))
}
onMounted(() => {
  loadUserAgents()
  getAccMetric()
  loadAccMetricInterval = setInterval(getAccMetric, 10000)
})
onBeforeUnmount(() => clearInterval(loadAccMetricInterval))
</script>
